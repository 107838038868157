$icon-size: 18px;
$icons: (
    'success': (
        'color': rgb(0, 139, 19),
        'icon': '\eed8',
    ),
    'error': (
        'color': rgb(255, 65, 65),
        'icon': '\f026',
    ),
    'warn': (
        'color': rgb(253, 180, 45),
        'icon': '\f026',
    ),
    'info': (
        'color': rgb(95, 159, 255),
        'icon': '\ef4e',
    ),
);

@each $type, $info in $icons {
    .notification-box.#{$type} {
        .notification-head {
            background-color: map-get($info, 'color');

            .progress {
                background-color: lighten(map-get($info, 'color'), 15%);
            }
        }

        .icon::after {
            content: map-get($info, 'icon');
        }
    }
}

#notificationsContainer {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    .notification-box {
        width: 100vw;
        //border: 1px solid #f1efef;
        border-radius: 5px;
        margin: 0 0.7rem 0.7rem 0;
        background: rgb(240, 240, 240);
        box-shadow: 0 0px 5px rgba(0, 0, 0, 0.65);
        padding: 0;
        overflow: hidden;

        @media screen and (min-width: 576px) {
            width: 300px;
        }

        .notification-head {
            padding: 3px 10px;
            position: relative;

            .icon {
                font-family: IcoFont !important;
                font-style: normal;
                font-weight: 400;
                font-variant: normal;
                text-transform: none;
                font-size: $icon-size;
                line-height: 1;
                z-index: 1;
            }

            .icofont-close {
                z-index: 1;
                cursor: pointer;
            }

            .progress {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 0%;
                border-radius: 0;
                z-index: 0;
                animation: dismissAnimation 4s linear 1 running;
            }
        }

        .notification-body {
            padding: 6px 10px;
        }
    }
}

@keyframes dismissAnimation {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}
