@import '../vendors/fonts/icofont/icofont.min.css';
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Raleway:wght@300;400;600;700&display=swap');

@import 'src/app/commons/variables';
@import 'node_modules/bootstrap/scss/bootstrap';

body {
    background-color: rgb(245, 245, 245);
}

.hidden {
    display: none;
}

.authExternalContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(245, 245, 245);
}

.outer-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.error-container-full-page {
    text-align: center;

    .error-code {
        font-size: 8em;
    }
}

.highlighted {
    color: #ffbe21;
}

.title-font {
    font-family: 'Playfair Display', serif;
    //font-size: 10vh;
    font-weight: 700;
}

.pageTitle {
    font-weight: bold;
    text-shadow: 2px 2px 15px rgba(255, 255, 255, 0.7);
}

.nav-buttons {
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset;
    padding: 0.2rem 0.8rem;
    border-radius: 0.3rem;

    .nav-button {
        font-size: 1.3rem;
        color: $gray-500;
        margin-right: 0.5rem;
        text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15);

        &:hover,
        &.active {
            color: $gray-900;
            text-decoration: none;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.text-extra-dark {
    color: black;
}

.light-bg {
    color: #000;
    background: #f6f2ff;
}

.under-title-tick {
    height: 4px;
    margin: 1rem auto 0 auto;
    bottom: 0;
    background: #cf1259;
}

.page-footer {
    background: #454545;
    color: white;
    z-index: 0;

    a {
        color: white;

        &:hover {
            text-decoration: underline;
        }
    }

    .nav-item {
        padding: 0.2rem 0;
        font-size: 0.9rem;
    }
}

.row-selected {
    background: rgba(0, 0, 0, 0.075);
}

.row-selectable {
    cursor: pointer;
}

.success {
    color: theme-color('success');

    &::before {
        content: '\ec4b';
        font-family: IcoFont !important;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        font-size: 1em;
    }
}

.error {
    color: theme-color('danger');

    &::before {
        content: '\ef16';
        font-family: IcoFont !important;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        font-size: 1em;
    }
}

@include media-breakpoint-down(sm) {
    table thead {
        display: none;
    }

    table td {
        display: flex;

        &::before {
            content: attr(data-title);
            font-weight: bold;
            min-width: 30%;
        }
    }

    .table-selector {
        display: none;
    }

    table tr {
        border-bottom: 2px solid theme-color('dark');

        &:last-child {
            border-bottom: none;
        }
    }
}

.dashboard_bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: max(110vh, 110vw);
        height: max(110vh, 110vw);
        z-index: -1;
        border-radius: 10%;
        transform: rotate(-25deg) translateY(-50%) translateX(10%);
        background: rgb(205, 0, 255);
        /*background: linear-gradient(
            50deg,
            rgba(205, 0, 255, 1) 0%,
            rgba(109, 25, 177, 1) 35%,
            rgba(105, 65, 205, 1) 100%
        );*/
        background: linear-gradient(
            50deg,
            rgba(205, 0, 255, 1) 0%,
            rgba(109, 25, 177, 1) 35%,
            rgba(70, 183, 242, 1) 100%
        );
    }
}

.expand-grow {
    flex-grow: 2;
}
